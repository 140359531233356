<template>
  <div class="container">
    <el-breadcrumb separator-class="el-icon-arrow-right" style="	height: 40px;">
      <el-breadcrumb-item>{{ fun.getLanguageText($store.state.language, '账号列表') }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ fun.getLanguageText($store.state.language, '添加账号') }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="ruleForm" :model="formData" :rules="rules" label-width="160px" status-icon>
      <el-form-item :label="fun.getLanguageText($store.state.language, '账号名称')" prop="name">
        <el-input v-model="formData.name" :disabled="$route.query.id"
          :placeholder="fun.getLanguageText($store.state.language, '请输入用户账号')"></el-input>
      </el-form-item>
      <el-form-item :label="fun.getLanguageText($store.state.language, '账号密码')" prop="password">
        <el-input v-model="formData.password" autocomplete="off"
          :placeholder="fun.getLanguageText($store.state.language, '请输入用户密码')" type="password">
        </el-input>
      </el-form-item>
      <el-form-item :label="fun.getLanguageText($store.state.language, '邮箱')" prop="email">
        <el-input v-model="formData.email" autocomplete="off"
          :placeholder="fun.getLanguageText($store.state.language, '请输入用户邮箱')"></el-input>
      </el-form-item>
      <el-form-item :label="fun.getLanguageText($store.state.language, '账号类型')" prop="role_id">
        <el-select v-model="formData.role_id" :disabled="$route.query.id"
          :placeholder="fun.getLanguageText($store.state.language, '请选择账号类型')" @change="changeRole">
          <el-option v-if="$store.state.roleId === 1" :label="fun.getLanguageText($store.state.language, '超级管理员')"
            value="1"></el-option>
          <el-option v-if="$store.state.roleId <= 2" :label="fun.getLanguageText($store.state.language, '管理员')"
            value="2"></el-option>
          <el-option :label="fun.getLanguageText($store.state.language, '客户')" value="3"></el-option>
          <el-option :label="fun.getLanguageText($store.state.language, '终端用户')" value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="($store.state.roleId === 1 && (formData.role_id === '4' || formData.role_id === '3')) && !$route.query.id"
        :label="fun.getLanguageText($store.state.language, '管理员公司')">
        <el-select v-model="admin_id" :placeholder="fun.getLanguageText($store.state.language, '请选择管理员公司')"
          @change="changeCompany($event, 'admin')">
          <el-option v-for="item in companyList_admin" :key="item.id" :label="item.companyName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="(formData.role_id === '4' && $store.state.roleId !== 3) && !$route.query.id"
        :label="fun.getLanguageText($store.state.language, '客户公司')">
        <el-select v-model="customer_id" :placeholder="fun.getLanguageText($store.state.language, '请选择客户公司')"
          @change="changeCompany($event, 'customer')">
          <el-option v-for="item in companyList_customer" :key="item.id"
            :label="fun.getLanguageText($store.state.language, item.companyName)" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="is_company && !$route.query.id" :label="fun.getLanguageText($store.state.language, '从属公司')"
        prop="companyId">
        <el-select v-model="formData.companyId" :placeholder="fun.getLanguageText($store.state.language, '请选择从属公司')">
          <el-option v-for="item in companyList" :key="item.id"
            :label="fun.getLanguageText($store.state.language, item.companyName)" :value="item.id">
          </el-option>
        </el-select>
        <div class="div"></div>
        <el-button class="add-company" type="primary" @click="goAddCompany">
          {{ fun.getLanguageText($store.state.language, "添加公司") }}
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="info" @click="cancel">{{ fun.getLanguageText($store.state.language, "取消") }}</el-button>
        <el-button type="primary" @click="create">{{ fun.getLanguageText($store.state.language, "确认") }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formData: {
        name: '',
        password: '',
        email: '',
        role_id: '',
        companyId: '',
        id: '',
        pid: ''
      },
      rules: {
        name: [{
          required: true,
          message: this.fun.getLanguageText(this.$store.state.language, '账号不能为空'),
          trigger: 'blur'
        },
        {
          min: 5,
          message: this.fun.getLanguageText(this.$store.state.language, '用户名不能小于5位'),
          trigger: 'blur'
        }
        ],
        password: [{
          required: true,
          message: this.fun.getLanguageText(this.$store.state.language, '密码不能为空'),
          trigger: 'blur'
        },
        {
          min: 5,
          message: this.fun.getLanguageText(this.$store.state.language, '密码不能小于5位'),
          trigger: 'blur'
        }
        ],
        email: [{
          required: true,
          message: this.fun.getLanguageText(this.$store.state.language, '邮箱不能为空'),
          trigger: 'blur'
        }, {
          type: 'email',
          message: this.fun.getLanguageText(this.$store.state.language, '请输入正确的邮箱地址'),
          trigger: 'blur'
        }],
        role_id: [{
          required: true,
          message: this.fun.getLanguageText(this.$store.state.language, '账号类型不能为空'),
          trigger: 'change'
        }],
        companyId: [{
          required: true,
          message: this.fun.getLanguageText(this.$store.state.language, '从属公司不能为空'),
          trigger: 'change'
        }]
      },
      companyList: [],
      roleList: [],
      companyList_admin: [], // 管理员公司 列表
      admin_id: '', // 当前选中管理员公司
      companyList_customer: [], // 客户公司 列表
      customer_id: '', // 当前选中客户公司
      companyList_client: [], // 终端客户公司 列表
      client_id: '', // 当前选中终端客户公司
    }
  },
  computed: {
    // 是否需要选中归属公司
    is_company: function () {
      // 判断账户权限
      if (this.$store.state.roleId === 1) { // 超管权限
        if (this.formData.role_id + '' === '1') { // 选中超管
          return false
        }
      } else if (this.$store.state.roleId === 2) { // 管理员权限
        if (this.formData.role_id + '' === '2') { // 选中管理员
          return false
        } else if (this.formData.role_id + '' === '3') { // 选中客户

        } else if (this.formData.role_id + '' === '4') { // 选中终端用户

        }
      } else if (this.$store.state.roleId === 3) { // 客户权限
        if (this.formData.role_id + '' === '3') { // 选中客户
          return false
        } else if (this.formData.role_id + '' === '4') { // 选中终端用户
          // return false
        }
      }
      return true
    }
  },
  created() {
    if (this.$route.query.id) {
      this.formData.id = this.$route.query.id
      this.getEdit()
    } else {

    }

  },
  methods: {
    // 手动选中上级用户[客户/管理员]
    async changeCompany(id, type) {
      console.log(id, type, this.formData.role_id + '')
      if (type === 'admin') {
        if (this.formData.role_id + '' === '3') {
          // 超管权限下->选择客户类型->选择了上级管理员
          this.formData.companyId = ''
          this.companyList = []
          this.companyList_customer = []
          this.customer_id = ''
          this.select(1, id)
          this.formData.pid = id
          console.log('选中上级pid', id)
        } else if (this.formData.role_id + '' === '4') {
          // 超管权限下->选择终端客户类型->选择了上上级管理员
          this.formData.companyId = ''
          this.companyList = []
          this.companyList_customer = []
          this.customer_id = ''
          this.getCustomerList(id)
        }
        //
      } else if (type === 'customer') {
        this.formData.companyId = ''
        this.companyList = []
        this.select(2, id)
        this.formData.pid = id
        console.log('选中上级pid', id)
      }
    },
    // 可选客户-列表
    async getCustomerList(pid) {
      let res = await this.$http.post('company/custom-list', {
        pid
      })
      if (res.data.code !== 2000) {
        // 读取公司列表失败
        return false
      }
      this.companyList_customer = res.data.data
      console.log(res.data)
      return true
    },
    // 可选管理员-列表
    async getAdminList() {
      let res = await this.$http.post('company/admin-list', {})
      if (res.data.code !== 2000) {
        // 读取公司列表失败
        if (res.data.msg === "没有权限") {
          this.getCustomerList()
        }
        return false
      }
      this.companyList_admin = res.data.data
      console.log(res.data)
      return true
    },
    // 跳转到 添加公司
    goAddCompany() {
      this.$router.push('/Home/UserList/AddUserList')
    },
    // 用户编辑获取内容
    getEdit() {
      this.$http.post('user/edit', {
        id: this.formData.id
      }).then(res => {
        if (res.data.code === 2000) {
          console.log(res.data)
          for (let key in res.data.data) {
            this.formData[key] = res.data.data[key] + ""
          }
          console.log(this.$store.state.roleId)
          this.changeRole(this.formData.role_id, true)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 选择归属公司
    select(id, pid) {
      this.$http.post('company/choose', {
        companyType: id,
        pid: pid
      }).then(res => {
        // console.log("选择归属公司", this.formData.role_id, res.data.data)
        if (res.data.code === 2000) {
          this.companyList = res.data.data.info
        }
      })
    },
    // 取消,退出本页面
    cancel() {
      this.$router.push('/Home/UserNameList')
    },
    // 改变账户类型
    changeRole(e, reg) {
      console.log(typeof this.formData.role_id)
      if (this.formData.role_id === "1") { // 选择 超管账号
        // 不显示归属公司
      } else if (this.formData.role_id === "2") { // 选择 管理员账号
        this.formData.companyId = ''
        this.companyList = []
        this.admin_id = ''
        this.customer_id = ''
        // 查询公司列表[所有管理员等级公司]
        this.select(-1)
        this.formData.pid = ''

      } else if (this.formData.role_id === "3") { // 选择 用户账号
        this.formData.companyId = ''
        this.companyList = []
        this.admin_id = ''
        this.customer_id = ''
        if (this.$store.state.roleId === 1) {
          this.getAdminList()
        } else if (this.$store.state.roleId === 2) {
          this.select(1)
          this.formData.pid = ''
        }
      } else if (this.formData.role_id === "4") { // 选择 终端用户账号
        this.formData.companyId = ''
        this.companyList = []
        this.admin_id = ''
        this.customer_id = ''
        if (this.$store.state.roleId === 1) {
          this.getAdminList()
        }
        if (this.$store.state.roleId === 2) {
          this.getCustomerList()
        }
        if (this.$store.state.roleId === 3) {
          this.select(2)
          this.formData.pid = ''
        }
      }
    },
    // 创建用户
    create() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$http.post('user/create', this.formData).then(res => {
            console.log(res)
            if (res.data.msg === "success") {
              this.$message({
                type: 'success',
                message: this.fun.getLanguageText(this.$store.state.language, '添加账户成功')
              });
              this.cancel()
              return
            }
            this.$message({
              type: 'warning',
              message: this.fun.getLanguageText(this.$store.state.language, res.data.msg)
            });
          }).catch(err => {
            console.log(err)
          })
        } else {
          console.log('error submit!!!');
          return false;
        }
      });
    },
    // 获取公司列表
    getCompanyList() {
      this.$http.post("company/list", {
        companyType: this.formData.role_id - 2
      }).then(res => {
        console.log('公司列表', res)
        if (res.data.msg === "success") {
          this.companyList = res.data.data.info
          this.companyList.forEach(item => {
            item.id = item.id + ""
          })
        } else {
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-form-item {
  .el-input {
    width: 500px;
  }

  .el-select {
    width: 500px;
  }

  .el-button {
    width: 200px;
  }

  .div {
    display: inline-block;
    width: 20px;
  }
}
</style>
